import { handleActions, createAction } from 'redux-actions';

// This reducer handles storing filter settings for the YouthRoster table

export const initialState = {
    orgList: [],
};

// Actions
const SET_ORG_LIST = 'app/orgList/SET_ORG_LIST';

// Action Creators
export const setOrgList = createAction(SET_ORG_LIST);

export const setValues = (form, value) => {
    return async (dispatch, getState) => {
        form.setFieldValue('multiSelect', value);
        dispatch(setOrgList(value));
    };
};
// Reducers
export default handleActions(
    {
        [SET_ORG_LIST]: (state, action) => ({
            ...state,
            orgList: action.payload,
        }),
    },
    initialState
);
