import groupingUnitQuery from '../components/SurveyRouting/groupingUnitQuery.graphql';
import profDevDocQuery from '../components/SurveyRouting/profDevDocQuery.graphql';
import { client } from 'cccisd-apollo';

export default async (params, orgList) => {
    const { deploymentHandle } = params;
    const isProfDevDoc = deploymentHandle === 'profDevDoc';

    const response = await client.query({
        query: isProfDevDoc ? profDevDocQuery : groupingUnitQuery,
        fetchPolicy: 'network-only',
        variables: {
            groupingUnitId: isProfDevDoc ? orgList.map(item => item.value) : +params.groupId,
            deploymentHash: params.deploymentHash,
        },
    });

    const variables = {};
    variables.deployment = response?.data?.flows.deployment;

    variables.staffMemberList = response?.data?.roles?.uberadminList.map(item => {
        return {
            pawnId: item.pawn.pawnId,
            pawnHash: item.pawn.pawnHash,
            name: item.user.fullName,
        };
    });
    variables.groupedAdminList = response?.data?.roles?.guAdminList.map(item => {
        return {
            pawnId: item.pawn.pawnId,
            pawnHash: item.pawn.pawnHash,
            name: item.user.fullName,
        };
    });
    // Dont need rest for that survey
    if (isProfDevDoc) {
        return variables;
    }

    variables.surveyList = response?.data?.flows?.deployment?.assignment?.surveyList?.map(item => item.surveyHandle);

    variables.otherAdminList = response?.data?.roles?.guAdminList.map(item => {
        return { pawnId: item.pawn.pawnId, pawnHash: item.pawn.pawnHash, name: item.user.fullName };
    });
    variables.staffMembers = response?.data?.roles?.uberadminList.map(item => {
        return { id: item.pawn.pawnId, name: item.user.fullName };
    });

    variables.otherAdmins = response?.data?.roles?.guAdminList.map(item => {
        return { id: item.pawn.pawnId, name: item.user.fullName };
    });

    const groupObj = response?.data?.groups?.groupingUnit;
    variables.orgName = groupObj.group.label;

    variables.metricsPawn = {
        pawnId: groupObj.childRoles.metricspawn.pawn.pawnId,
        pawnHash: groupObj.childRoles.metricspawn.pawn.pawnHash,
    };

    variables.groupedAdminList = response?.data?.groups?.groupingUnit?.childRoles?.guAdminList.map(item => {
        return {
            pawnId: item.pawn.pawnId,
            pawnHash: item.pawn.pawnHash,
            name: item.user.fullName,
        };
    });
    variables.groupedAdmins = response?.data?.groups?.groupingUnit?.childRoles?.guAdminList.map(item => {
        return { pawnId: item.pawn.pawnId, name: item.user.fullName };
    });

    return variables;
};
