import React, { useRef } from 'react';
import Table from 'cccisd-graphql-table';
import style from './style.css';
import StatusTag from './renders/StatusTag';
import EditSurvey from './renders/EditSurvey';
import EditDeployment from './renders/EditDeployment';
import AddDeployment from './components/AddDeployment';
import uberProfDevQuery from './graphql/uberProfDevQuery.graphql';
import uberProfDevDocQuery from './graphql/uberProfDevDocQuery.graphql';
import uberSiteVisitQuery from './graphql/uberSiteVisitQuery.graphql';
import uberFeedbackQuery from './graphql/uberFeedbackQuery.graphql';
import guAdminFeedbackQuery from './graphql/guAdminFeedbackQuery.graphql';
import guAdminProfDevQuery from './graphql/guAdminProfDevQuery.graphql';
import facilitatorFormQuery from './graphql/facilitatorFormQuery.graphql';

const Fortress = window.cccisd.fortress;

const DashboardTable = ({ redirectTo, groupingUnitId, deploymentId, deploymentInfo, actingRole }) => {
    const tableRef = useRef();
    const isSiteVisit = deploymentInfo.handle === 'siteVisit';
    const isBiannualFeedback = deploymentInfo.handle === 'biannualFeedback';
    const isProfDev = deploymentInfo.handle === 'profDev';
    const isProfDevDoc = deploymentInfo.handle === 'profDevDoc';
    const isUber = actingRole === 'uberadmin';
    const isOrgAdmin = actingRole === 'guAdmin';
    const isFacilitator = actingRole === 'instructor';

    const uberSiteVisitColumns = [
        {
            handle: 'visitedDate',
            label: 'Site Visit Date',
            name: 'siteVisitDate.dt_3307',
            className: 'text-center',
            filter: true,
            sort: true,
            filterSettings: { type: 'date' },
        },
        {
            handle: 'fullName',
            label: 'Name',
            name: 'lastUpdatedBy',
            sort: true,
            render: ({ row }) => {
                const string = row.lastUpdatedBy?.split(', ');
                if (string && string.length > 0) {
                    return string[0];
                }
                return null;
            },
        },
        {
            handle: 'email',
            label: 'Email',
            name: 'lastUpdatedBy2',
            sort: true,
            render: ({ row }) => {
                const string = row.lastUpdatedBy?.split(', ');
                if (string && string.length > 0) {
                    return string[1];
                }
                return null;
            },
        },
        {
            handle: 'status',
            label: 'Status',
            name: 'status',
            filter: true,
            sort: true,
            render: props => {
                return <StatusTag props={props} />;
            },
        },
        {
            handle: 'completedAt',
            label: 'Completed At',
            name: 'completedDate',
            filter: true,
            sort: true,
            filterSettings: { type: 'date' },
            className: 'text-center',
        },
        {
            handle: 'actions',
            label: 'Action',
            name: 'deployment.deploymentId',
            className: 'text-center',
            render: props => {
                const status = props.row.status;

                if (status === 'In Progress') {
                    return (
                        <div>
                            <EditSurvey
                                deploymentId={props.row['deployment.deploymentId']}
                                deploymentHash={props.row['deployment.hash']}
                                groupId={groupingUnitId}
                                pawnId={props.row['pawn.pawn.pawnId']}
                                pawnHash={props.row['pawn.pawn.pawnHash']}
                                loadData={props.loadData}
                            />
                        </div>
                    );
                }

                if (status && status !== 'Not Started' && status !== 'In Progress') {
                    return (
                        <div>
                            <EditSurvey
                                deploymentId={props.row['deployment.deploymentId']}
                                deploymentHash={props.row['deployment.hash']}
                                groupId={groupingUnitId}
                                pawnId={props.row['pawn.pawn.pawnId']}
                                pawnHash={props.row['pawn.pawn.pawnHash']}
                                isView
                                loadData={props.loadData}
                            />
                        </div>
                    );
                }
            },
        },
    ];
    const uberProfDevDocColumns = [
        {
            handle: 'sessionDate',
            label: 'Session Date',
            name: 'sessionDate.dt_4397',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
        },
        {
            handle: 'sessionType',
            label: 'Session Type',
            name: 'sessionType',
            sort: true,
            filter: true,
        },
        {
            handle: 'selectedOrgs',
            label: 'Selected Orgs',
            name: 'selectedOrgs',
            sort: true,
            filter: true,
        },
        {
            handle: 'status',
            label: 'Status',
            name: 'status',
            filter: true,
            sort: true,
            render: props => {
                return <StatusTag props={props} />;
            },
        },
        {
            handle: 'completedAt',
            label: 'Completed At',
            name: 'completedDate',
            filter: true,
            sort: true,
            filterSettings: { type: 'date' },
            className: 'text-center',
        },
        {
            handle: 'actions',
            label: 'Action',
            name: 'deployment.deploymentId',
            className: 'text-center',
            render: props => {
                const status = props.row.status;

                if (status === 'In Progress') {
                    return (
                        <div>
                            <EditSurvey
                                deploymentId={props.row['deployment.deploymentId']}
                                deploymentHash={props.row['deployment.hash']}
                                groupId={groupingUnitId}
                                pawnId={props.row['pawn.pawn.pawnId']}
                                pawnHash={props.row['pawn.pawn.pawnHash']}
                                loadData={props.loadData}
                            />
                        </div>
                    );
                }
                return null;
            },
        },
    ];

    const uberBiannualColumns = [
        {
            handle: 'visitedDate',
            label: 'Site Visit Date',
            name: 'lastVisitedDate',
            filter: true,
            sort: true,
            filterSettings: { type: 'date' },
        },
        {
            handle: 'fullName',
            label: 'Name',
            name: 'lastUpdatedBy',
            sort: true,
            render: ({ row }) => {
                const string = row.lastUpdatedBy?.split(', ');
                return string ? string[0] : 'N/A';
            },
        },
        {
            handle: 'email',
            label: 'Email',
            name: 'lastUpdatedBy2',
            sort: true,
            render: ({ row }) => {
                const string = row.lastUpdatedBy?.split(', ');
                return string ? string[1] : 'N/A';
            },
        },
        {
            handle: 'status',
            label: 'Status',
            name: 'status',
            filter: true,
            sort: true,
            render: props => {
                return <StatusTag props={props} />;
            },
        },
        {
            handle: 'completedAt',
            label: 'Completed At',
            name: 'completedDate',
            filter: true,
            sort: true,
            filterSettings: { type: 'date' },
        },
        {
            handle: 'actions',
            label: 'Action',
            name: 'deployment.deploymentId',
            render: props => {
                const status = props.row.status;

                if (Fortress.user.acting.id === props.row['pawn.pawnId'] && status === 'In Progress') {
                    return (
                        <div>
                            <EditSurvey
                                deploymentId={props.row['deployment.deploymentId']}
                                deploymentHash={props.row['deployment.hash']}
                                groupId={groupingUnitId}
                                pawnId={props.row['pawn.pawn.pawnId']}
                                pawnHash={props.row['pawn.pawn.pawnHash']}
                            />
                        </div>
                    );
                }

                if (status && status !== 'Not Started') {
                    return (
                        <div>
                            <EditSurvey
                                deploymentId={props.row['deployment.deploymentId']}
                                deploymentHash={props.row['deployment.hash']}
                                groupId={groupingUnitId}
                                pawnId={props.row['pawn.pawn.pawnId']}
                                pawnHash={props.row['pawn.pawn.pawnHash']}
                                isView
                            />
                        </div>
                    );
                }
            },
        },
    ];

    const facilitatorFormColumns = [
        {
            handle: 'sessionId',
            label: 'Session Id',
            name: 'deploymentId',
            className: 'text-center',
            filter: true,
            sort: true,
        },
        {
            handle: 'organization',
            label: 'TA Org',
            name: 'settings.orgName',
            className: 'text-center',
            filter: true,
            sort: true,
        },
        {
            handle: 'program',
            label: 'Program(s)',
            name: 'label',
            className: 'text-center',
            filter: true,
            sort: true,
        },
        {
            handle: 'openDate',
            label: 'Open Date',
            name: 'opensAt',
            filter: true,
            sort: true,
        },
        {
            handle: 'closeDate',
            label: 'Close Date',
            name: 'closesAt',
            filter: true,
            sort: true,
        },
        {
            handle: 'status',
            label: 'Status',
            name: 'isOpen',
            filter: true,
            sort: true,
            render: props => {
                return <StatusTag props={props} type="open" />;
            },
        },
        {
            handle: 'responses',
            label: 'Completed At',
            name: 'completedResponses',
            sort: true,
            render: ({ row }) => {
                return `${row.completedResponses} out of ${row.totalResponses}`;
            },
        },
        {
            handle: 'actions',
            label: 'Action',
            name: 'hash',

            render: props => {
                return <EditDeployment {...props} />;
            },
        },
    ];
    const uberProfDevColumns = [
        {
            handle: 'name',
            label: 'Name',
            name: 'label',
            className: 'text-center',
            filter: true,
            sort: true,
        },
        {
            handle: 'openDate',
            label: 'Open Date',
            name: 'opensAt',
            filter: true,
            sort: true,
        },
        {
            handle: 'closeDate',
            label: 'Close Date',
            name: 'closesAt',
            filter: true,
            sort: true,
        },
        {
            handle: 'status',
            label: 'Status',
            name: 'isOpen',
            filter: true,
            sort: true,
            render: props => {
                return <StatusTag props={props} type="open" />;
            },
        },
        {
            handle: 'responses',
            label: 'Completed At',
            name: 'completedResponses',
            sort: true,
            render: ({ row }) => {
                return `${row.completedResponses} out of ${row.totalResponses}`;
            },
        },
        {
            handle: 'actions',
            label: 'Action',
            name: 'deploymentId',

            render: props => {
                // if (Fortress.user.acting.id === props.row['pawn.pawnId'] && status === 'In Progress') {
                //     return (
                //         <div>
                //             <EditSurvey
                //                 deploymentId={props.row['deployment.deploymentId']}
                //                 deploymentHash={props.row['deployment.hash']}
                //                 groupId={groupingUnitId}
                //                 pawnId={props.row['pawn.pawn.pawnId']}
                //                 pawnHash={props.row['pawn.pawn.pawnHash']}
                //             />
                //         </div>
                //     );
                // }

                // if (status && status !== 'Not Started') {
                //     return (
                //         <div>
                //             <EditSurvey
                //                 deploymentId={props.row['deployment.deploymentId']}
                //                 deploymentHash={props.row['deployment.hash']}
                //                 groupId={groupingUnitId}
                //                 pawnId={props.row['pawn.pawn.pawnId']}
                //                 pawnHash={props.row['pawn.pawn.pawnHash']}
                //                 isView
                //             />
                //         </div>
                //     );
                // }
                return null;
            },
        },
    ];

    const renderQuestLink = () => {
        if (deploymentInfo.handle === 'profDev') {
            return (
                <div className={style.rightAlign}>
                    <AddDeployment assignmentId={deploymentInfo.assignmentId} orgId={groupingUnitId} table={tableRef} />
                </div>
            );
        }

        return (
            <div className={style.flex}>
                <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!deploymentInfo.isOpen}
                    onClick={() => {
                        const url = `/d/${deploymentInfo.hash}/${groupingUnitId}/${deploymentInfo.handle}`;
                        redirectTo(url);
                    }}
                >
                    Take Survey
                </button>

                <div
                    className={style.status}
                    style={
                        deploymentInfo.isOpen
                            ? {
                                  color: '#5CB85C',
                              }
                            : {
                                  color: '#F85152',
                              }
                    }
                >
                    {deploymentInfo.isOpen ? 'OPEN' : 'CLOSED'}
                </div>
            </div>
        );
    };

    const getColumns = () => {
        if (isUber) {
            if (isSiteVisit) {
                return uberSiteVisitColumns;
            }
            if (isBiannualFeedback) {
                return uberBiannualColumns;
            }
            if (isProfDev) {
                return uberProfDevColumns;
            }
            if (isProfDevDoc) {
                return uberProfDevDocColumns;
            }
        }
        if (isOrgAdmin) {
            if (isBiannualFeedback) {
                return uberBiannualColumns;
            }
            if (isProfDev) {
                return uberProfDevColumns;
            }
        }
        if (isFacilitator) {
            return facilitatorFormColumns;
        }
    };

    const getGqlVariables = () => {
        if (isUber) {
            if (isSiteVisit) {
                return {
                    orgName: deploymentInfo.orgName,
                    deploymentHandle: deploymentInfo.handle,
                };
            }
            if (isBiannualFeedback) {
                return {
                    groupingUnitId,
                    deploymentHandle: deploymentInfo.handle,
                };
            }
            if (isProfDev) {
                const pattern = `\\"orgId\\": ${groupingUnitId}`;

                return {
                    assignmentId: deploymentInfo.assignmentId,
                    groupRegex: pattern,
                };
            }
            if (isProfDevDoc) {
                return {
                    deploymentHandle: deploymentInfo.handle,
                    pawnId: Fortress.user.acting.id,
                };
            }
        }
        if (isOrgAdmin) {
            if (isBiannualFeedback) {
                return {
                    groupingUnitId,
                    deploymentHandle: deploymentInfo.handle,
                };
            }
            if (isProfDev) {
                const pattern = `\\"orgId\\": ${groupingUnitId}`;

                return {
                    assignmentId: deploymentInfo.assignmentId,
                    groupRegex: pattern,
                };
            }
        }
        if (isFacilitator) {
            const pattern = `\\"orgId\\": ${groupingUnitId}`;
            return {
                assignmentId: deploymentInfo.assignmentId,
                groupRegex: pattern,
            };
        }
    };

    const getQuery = () => {
        if (isUber) {
            if (isSiteVisit) {
                return uberSiteVisitQuery;
            }
            if (isBiannualFeedback) {
                return uberFeedbackQuery;
            }
            if (isProfDev) {
                return uberProfDevQuery;
            }
            if (isProfDevDoc) {
                return uberProfDevDocQuery;
            }
        }
        if (isOrgAdmin) {
            if (isBiannualFeedback) {
                return guAdminFeedbackQuery;
            }
            if (isProfDev) {
                return guAdminProfDevQuery;
            }
        }
        if (isFacilitator) {
            return facilitatorFormQuery;
        }
    };
    const getRowKey = () => {
        if (isUber) {
            if (isSiteVisit) {
                return 'assignmentProgressId';
            }
            if (isBiannualFeedback) {
                return 'assignmentProgressId';
            }
            if (isProfDev) {
                return 'deploymentId';
            }
            if (isProfDevDoc) {
                return 'assignmentProgressId';
            }
        }

        if (isOrgAdmin) {
            if (isBiannualFeedback) {
                return 'assignmentProgressId';
            }
            if (isProfDev) {
                return 'deploymentId';
            }
        }
        if (isFacilitator) {
            return 'deploymentId';
        }
    };
    const getOrderBy = () => {
        if (isUber) {
            if (isSiteVisit) {
                return 'siteVisitDate.dt_3307';
            }
            if (isBiannualFeedback) {
                return 'lastVisitedDate';
            }
            if (isProfDev) {
                return 'label';
            }
            if (isProfDevDoc) {
                return 'assignmentProgressId';
            }
        }

        if (isOrgAdmin) {
            if (isBiannualFeedback) {
                return 'lastVisitedDate';
            }
            if (isProfDev) {
                return 'label';
            }
        }
        if (isFacilitator) {
            return 'label';
        }
    };
    // console.log({
    //     deploymentId,
    //     rowkey: getRowKey(),
    //     query: getQuery(),
    //     variables: getGqlVariables(),
    //     columns: getColumns(),
    //     orderBy: getOrderBy(),
    // });

    return (
        <>
            {renderQuestLink()}
            <Table
                key={deploymentInfo.handle}
                ref={tableRef}
                rowKey={getRowKey()}
                query={getQuery()}
                graphqlVariables={getGqlVariables()}
                columns={getColumns()}
                orderBy={getOrderBy()}
            />
        </>
    );
};

export default DashboardTable;
