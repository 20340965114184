import Modal from 'cccisd-modal';
import React, { useRef } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdDatepicker } from 'cccisd-formik';
import axios from 'cccisd-axios';
import PlusIcon from 'cccisd-icons/plus';
import moment from 'moment';
import style from './style.css';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

const AddDeployment = props => {
    const modal = useRef();

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    };

    const onSubmit = async values => {
        try {
            const params = {
                assignmentId: props.assignmentId,
                description: null,
                hash: Math.random().toString(36).substring(2, 9),
                isOpen: false,
                label: values.deploymentName,
                respondentsFromDcwId: null,
                roleHandle: 'respondent',
                opensAt: applyTimezoneOffset(values.openDate),
                closesAt: applyTimezoneOffset(values.closeDate),
                settings: {
                    options: ['survey172'],
                    orgId: props.orgId,
                    recaptcha: false,
                    autoLogout: true,
                    isRepeated: false,
                    allowRetake: false,
                    requirePass: false,
                    useEndpoint: false,
                    closedRoster: false,
                    requireLogin: true,
                    closeOnTarget: false,
                    closedMessage: 'This survey is no longer accepting responses.',
                    completionUrl: null,
                    passcodeLabel: 'Your Passcode',
                    autoLogoutTime: 20,
                    repeatInterval: null,
                    completedMessage: 'You have already completed this survey. Thank you!',
                    respondentConfig: 'managed',
                    completionMessage: 'You have completed the survey! Thank you for your response!',
                    otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                    chainEmailFromPawn: null,
                    chainPhoneFromPawn: null,
                    individualPasscode: false,
                    logoutOnCompletion: false,
                    passcodeBackground: 'solidColor',
                    respondentSelection: 'metricspawn',
                    postCompletionAction: 'message',
                    anonAutoLogoutMessage:
                        '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity.</p>\n                    <p>Sorry! <a href="https://pcanc.test/d/9frmzn1" target="_blank" rel="noreferrer noopener">Start a new session</a></p>',
                    knownAutoLogoutMessage:
                        '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity. Sorry!</p>\n                        <p>You can resume your session from the link you were given, or contact your survey administrator for further assistance.</p>',
                    passcodeBackgroundColor: '#F1F0F5',
                    branchedCompletionConfig: [],
                    isAssignmentPlanEligible: false,
                    passcodeCompletionButtonText: 'Ok',
                    showReviewButtonOnCompletion: false,
                    metricToRespondentPropertyMappings: [],
                },
                timepoint: '',
                type: 'group',
            };

            const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), params);

            if (response?.data?.status === 'success') {
                closeModal();
                props.table.current.loadData();
            } else {
                closeModal();

                if (response?.data?.errors?.closesOn) {
                    notification({ message: 'Close Date must be after Open Date', type: 'danger' });
                }
                if (response?.data?.errors?.opensAt) {
                    notification({ message: 'Open Date must be in the future', type: 'danger' });
                }
                if (response?.data?.errors?.group) {
                    notification({
                        message: 'You do not have permission to perform this action',
                        type: 'danger',
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const closeModal = () => {
        modal.current.close();
    };

    const validate = values => {
        try {
            let errors = {};
            if (!values.deploymentName) {
                errors.deploymentName = 'Deployment Name is required';
            }

            if (!values.openDate) {
                errors.openDate = 'Open Date is required';
            }

            if (!values.closeDate) {
                errors.closeDate = 'Close Date is required';
            }

            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-primary">
                    <PlusIcon spaceRight /> Add Deployment
                </button>
            }
            title="Add Deployment"
            size="medium"
        >
            <Formik
                onSubmit={onSubmit}
                validate={validate}
                render={() => (
                    <Form>
                        <label className={`control-label ${style.required}`}>Deployment Name:</label>
                        <Field name="deploymentName" component={CccisdInput} />
                        <div className={style.dates}>
                            <div>
                                <label className={`control-label ${style.required}`}>Open Date:</label>
                                <Field name="openDate" component={CccisdDatepicker} showTimeSelect />
                            </div>
                            <div>
                                <label className={`control-label ${style.required}`}>Close Date:</label>
                                <Field name="closeDate" component={CccisdDatepicker} showTimeSelect />
                            </div>
                        </div>
                        <div className={style.dates}>
                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                Back
                            </button>
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default AddDeployment;
