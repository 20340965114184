import Modal from 'cccisd-modal';
import React, { useState, useRef } from 'react';
import getGroupAdminData from '../../../../../helpers/getGroupAdminData';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import style from './style.css';
const Fortress = window.cccisd.fortress;

const EditSurvey = ({ deploymentId, deploymentHash, groupId, pawnId, pawnHash, isView, loadData }) => {
    const [settings, setSettings] = useState(null);
    const modal = useRef();

    const getComponentProps = () => {
        return {
            deploymentId,
            pawnId,
            pawnHash,
            flowProps: {
                hidePlayButton: true,
                userLists: {
                    staffMemberList: settings.staffMemberList,
                    groupedOrgAdminList: settings.groupedAdminList,
                    otherOrgAdminList: settings.otherAdminList,
                },
                variables: {
                    staffMembers: settings.staffMembers,
                    organizationName: settings.orgName,
                    groupedOrgAdmins: settings.groupedAdmins,
                    otherOrgAdmins: settings.otherAdmins,
                    lastUpdatedBy: `${Fortress.user.acting.user.full_name}, ${Fortress.user.acting.user.username}`,
                },
                isPrintMode: isView,
            },
        };
    };
    const closeModal = () => {
        modal.current.close();
        loadData();
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className={isView ? 'btn btn-primary' : 'btn btn-success'}>
                    {isView ? 'View' : 'Edit'}
                </button>
            }
            title="Edit Survey"
            size="large"
            beforeShow={async () => {
                const variables = await getGroupAdminData({ groupId, deploymentHash });

                setSettings(variables);
            }}
            beforeClose={() => {
                loadData();
            }}
        >
            <div className={style.fixContainer}>
                {settings && (
                    <DeploymentPlayer
                        {...getComponentProps()}
                        onComplete={() => {
                            closeModal();
                        }}
                    />
                )}
            </div>
        </Modal>
    );
};

export default EditSurvey;
