import React from 'react';
import style from './style.css';

export default ({ type, props: { value } }) => {
    if (type === 'open') {
        if (value) {
            return <div className={`${style.green} ${style.tag}`}>Open</div>;
        }
        return <div className={`${style.red} ${style.tag}`}>Closed</div>;
    }
    if (value === 'Complete') {
        return <div className={`${style.green} ${style.tag}`}>Complete</div>;
    }
    if (value === 'In Progress') {
        return <div className={`${style.yellow} ${style.tag}`}>In Progress</div>;
    }
    if (value === 'Not Started' || !value) {
        return <div className={`${style.red} ${style.tag}`}>Not Started</div>;
    }
    return 'N/A';
};
