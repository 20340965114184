import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import getGroupAdminData from '../../helpers/getGroupAdminData';
import { connect } from 'react-redux';
const Fortress = window.cccisd.fortress;
const actingId = Fortress.user.acting.id;
const actingHash = Fortress.user.acting.random_hash;
const Boilerplate = window.cccisd.boilerplate;

const SurveyRouting = props => {
    const params = useParams();
    const { deploymentHandle } = params;
    const [loading, setLoading] = useState(true);
    const [configData, setConfigData] = useState(null);

    const isSiteVisit = deploymentHandle === 'siteVisit';
    const isBiannualFeedback = deploymentHandle === 'biannualFeedback';
    const isProfDevDoc = deploymentHandle === 'profDevDoc';

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const settings = await getGroupAdminData(params, props.orgList);
        setConfigData(settings);
        setLoading(false);
    };

    const getComponentProps = () => {
        const useActingPawn = isSiteVisit || isBiannualFeedback || isProfDevDoc;
        if (isProfDevDoc) {
            return {
                ...props,
                deploymentId: configData.deployment.deploymentId,
                pawnId: actingId,
                pawnHash: actingHash,
                flowProps: {
                    hidePlayButton: true,
                    userLists: {
                        staffMemberList: configData.staffMemberList,
                        groupedOrgAdminList: configData.groupedAdminList,
                        orgList: props.orgList.map(item => {
                            return {
                                pawnId: item.pawnId,
                                pawnHash: item.pawnHash,
                                name: item.label,
                            };
                        }),
                    },
                    variables: {},
                },
            };
        }
        return {
            ...props,
            deploymentId: configData.deployment.deploymentId,
            pawnId: useActingPawn ? actingId : configData.metricsPawn.pawnId,
            pawnHash: useActingPawn ? actingHash : configData.metricsPawn.pawnHash,
            flowProps: {
                hidePlayButton: true,
                userLists: {
                    staffMemberList: configData.staffMemberList,
                    groupedOrgAdminList: configData.groupedAdminList,
                    otherOrgAdminList: configData.otherAdminList,
                },
                variables: {
                    staffMembers: configData.staffMembers,
                    organizationName: configData.orgName,
                    groupedOrgAdmins: configData.groupedAdmins,
                    otherOrgAdmins: configData.otherAdmins,
                    lastUpdatedBy: `${Fortress.user.acting.user.full_name}, ${Fortress.user.acting.user.username}`,
                },
            },
        };
    };

    const onComplete = async () => {
        // CHANGE NEEDED!!!! - get Label from survey question query
        if (configData.deployment.deploymentHandle === 'facilitatorForm') {
            try {
                const settings = {
                    assignmentId: configData.deployment.assignment.assignmentId,
                    description: null,
                    hash: Math.random().toString(36).substring(2, 9),
                    isOpen: false,
                    label: 'TestName3',
                    respondentsFromDcwId: null,
                    roleHandle: 'respondent',
                    opensAt: '',
                    closesAt: '',
                    settings: {
                        options: configData.surveyList,
                        orgId: +params.groupId,
                        orgName: configData.orgName,
                        recaptcha: false,
                        autoLogout: true,
                        isRepeated: false,
                        allowRetake: false,
                        requirePass: false,
                        useEndpoint: false,
                        closedRoster: false,
                        requireLogin: true,
                        closeOnTarget: false,
                        closedMessage: 'This survey is no longer accepting responses.',
                        completionUrl: null,
                        passcodeLabel: 'Your Passcode',
                        autoLogoutTime: 20,
                        repeatInterval: null,
                        completedMessage: 'You have already completed this survey. Thank you!',
                        respondentConfig: 'managed',
                        completionMessage: 'You have completed the survey! Thank you for your response!',
                        otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                        chainEmailFromPawn: null,
                        chainPhoneFromPawn: null,
                        individualPasscode: false,
                        logoutOnCompletion: false,
                        passcodeBackground: 'solidColor',
                        respondentSelection: 'metricspawn',
                        postCompletionAction: 'message',
                        anonAutoLogoutMessage:
                            '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity.</p>\n                    <p>Sorry! <a href="https://pcanc.test/d/9frmzn1" target="_blank" rel="noreferrer noopener">Start a new session</a></p>',
                        knownAutoLogoutMessage:
                            '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity. Sorry!</p>\n                        <p>You can resume your session from the link you were given, or contact your survey administrator for further assistance.</p>',
                        passcodeBackgroundColor: '#F1F0F5',
                        branchedCompletionConfig: [],
                        isAssignmentPlanEligible: false,
                        passcodeCompletionButtonText: 'Ok',
                        showReviewButtonOnCompletion: false,
                        metricToRespondentPropertyMappings: [],
                    },
                    timepoint: '',
                    type: 'group',
                };

                const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), settings);

                if (response?.data?.status === 'success') {
                    window.location = Boilerplate.url('dashboard');
                } else {
                    notification({ message: response?.data?.errors?.group[0], type: 'danger' });
                }
            } catch (e) {
                console.error(e);
            }
        }
    };
    if (loading) {
        return <Loader loading />;
    }

    return (
        <>
            <DeploymentPlayer {...getComponentProps()} onComplete={onComplete} />
        </>
    );
};

const mapStateToProps = state => ({
    orgList: state.app.orgList.orgList,
});

export default connect(mapStateToProps, {})(SurveyRouting);
